import React, { useEffect, useMemo, useState } from 'react'
import { MoreButton } from '@entities'
import { Document } from '@shared/ui'
import { Styled } from './styled'

const MAX_DOCUMENTS_COUNT = 10

export const DocumentListDesktop = ({ documents, titleLoadingMore }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(
    () => () => {
      setIsOpen(false)
    },
    [documents]
  )

  const isShowMore = useMemo(() => documents?.length > MAX_DOCUMENTS_COUNT, [documents])

  const [evenColumns, oddColumns] = useMemo(() => {
    const data = isOpen ? documents : documents.slice(0, MAX_DOCUMENTS_COUNT)
    const result = [[], []]

    data.forEach((item, index) => result[index % 2].push(item))

    return result
  }, [isOpen, documents])

  return (
    <Styled.Container>
      <Styled.Item>
        {evenColumns.map((document, index) => (
          <Document key={index} {...document} />
        ))}
      </Styled.Item>
      {Boolean(oddColumns.length) && (
        <Styled.Item>
          {oddColumns.map((document, index) => (
            <Document key={index} {...document} />
          ))}
        </Styled.Item>
      )}
      {isShowMore && !isOpen && (
        <Styled.MoreContainer>
          <MoreButton onClick={() => setIsOpen(true)}>{titleLoadingMore}</MoreButton>
        </Styled.MoreContainer>
      )}
    </Styled.Container>
  )
}
