import styled from 'styled-components'
import { theme } from '@shared/config'

const Container = styled.div`
  > :not(:last-child) {
    margin-bottom: ${theme.spacings.xs3}px;
  }
`

export const Styled = {
  Container,
}
