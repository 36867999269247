import React, { FC, useCallback } from 'react'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfoDisclosure } from '../types'
import { DocumentGroup } from './DocumentGroup'
import { useActiveGroup } from '../lib/useActiveGroup'
import { Info } from './Info'
import { DocumentListDesktop } from './DocumentListDesktop'
import { Styled } from '../styled'

export const InfoDisclosure: FC<TInfoDisclosure> = ({ title, docGroups, titleLoadingMore = 'Загрузить ещё' }) => {
  const [active, setActive] = useActiveGroup({ defaultActive: docGroups?.[0].alias })

  const { info, documents } = docGroups.find(({ alias }) => alias === active)

  const handleClickGroup = useCallback(
    (alias: string) => {
      setActive(alias)
    },
    [setActive]
  )

  return (
    <>
      <Text {...theme.header} marginBottomMob={theme.spacings.md2}>
        {title}
      </Text>
      <Styled.Container>
        <Styled.LeftContainer>
          {docGroups.map(({ title, documents, alias, info }) => (
            <DocumentGroup
              alias={alias}
              title={title}
              key={alias}
              info={info}
              isActive={alias === active}
              onClick={() => handleClickGroup(alias)}
              documents={documents}
              titleLoadingMore={titleLoadingMore}
            />
          ))}
        </Styled.LeftContainer>
        <div>
          {!!info && <Info {...info} />}
          {!!documents && <DocumentListDesktop documents={documents} titleLoadingMore={titleLoadingMore} />}
        </div>
      </Styled.Container>
    </>
  )
}
