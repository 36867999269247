import React from 'react'
import { List } from '@entities'
import { Text } from '@shared/ui'
import { theme } from '@shared/config'
import { TInfo } from '../../types'
import { Styled } from './styled'

export const Info = ({ title, elements, paragraphs }: TInfo) => (
  <Styled.Container>
    <Text fontSizeMob={theme.fonts.fontSizes.sm2} fontWeightMob={theme.fonts.fontWeights.medium}>
      {title}
    </Text>
    {paragraphs.map((paragraph) => (
      <Text key={paragraph.substring(0, 100)}>{paragraph}</Text>
    ))}
    <List {...elements} />
  </Styled.Container>
)
